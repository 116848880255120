<div id="cart-preview">
  <div class="cp-header">
    <div class="ttl">{{ 'HEADER.PREVIEW_CART.TITLE' | translate }}</div>
    <div class="close" (click)="closeCart()"></div>
  </div>

  <div class="cp-body">
    <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
    <ng-template #emptyCart>
      <div class="empty-cart">
        <div class="title">{{ 'HEADER.PREVIEW_CART.EMPTY' | translate }}</div>
      </div>
    </ng-template>

    <ng-container *ngIf="cart && cart.items && cart.items.length > 0; else emptyCart">
      <div class="cart-items">
        <div class="cart-item" *ngFor="let item of cart.items; let itemIndex = index">
          <div class="ci-img">
            <img alt="store"  [src]="urlProductMediumMedia(item.product.medias[0])"
                 *ngIf="!item.variation && item.product.medias && item.product.medias.length > 0"/>
            <img alt="store"  [src]="urlVariantMediumMedia(item.variation.media)"
                 *ngIf="item.variation && item.variation.media"/>
            <img alt="store"  [src]="item.product.imageLinks[0]"
                 *ngIf="(!item.product.medias || item.product.medias.length == 0) && (!item.variation || !item.variation.media)
                          && item.product.imageLinks && item.product.imageLinks.length > 0"/>

            <div class="product-discounts" *ngIf="item.discounts || item.upsell">
              <ng-container *ngIf="item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent)">
                <div class="discount">
                  -<span *ngIf="item.upsell.fixedDiscount">{{item.upsell.fixedDiscount | price}}</span>
                  <span *ngIf="item.upsell.discountPercent">{{item.upsell.discountPercent}}%</span>
                </div>
              </ng-container>
              <ng-container *ngFor="let discount of item.discounts; let index = index">
                <div class="discount">
                  -<span *ngIf="discount.value">{{discount.value | price}}</span>
                  <span *ngIf="discount.percent">{{discount.percent}}%</span>
                </div>
              </ng-container>
            </div>

          </div>
          <div class="ci-product">

            <div class="product-label">
              <a href="/product/{{item.product.slug}}">{{item.product.name}}</a>
              <div class="delete" (click)="removeItem(itemIndex)">
              </div>
            </div>

            <div class="product-variations" *ngIf="item.variation">
              <div class="variation-container">
                <ng-container *ngFor="let option of item.variation.optionValues; let index = index">
                  <div class="variation">{{option}}</div>
                  <div class="sep" *ngIf="index < (item.variation.optionValues.length - 1 )">-</div>
                </ng-container>
              </div>
            </div>
            <div class="product-footer">
              <div class="pf-container">
                <div class="product-price">
                  <span>{{getDiscountedPrice(item) || 0 | price}}</span>
                </div>
                <div class="product-actions">
                  <lib-neo-quantity [min]="1" [(value)]="item.quantity"
                                    (valueChange)="refreshTotal()"></lib-neo-quantity>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
  <ng-container *ngIf="cart && cart.items && cart.items.length  > 0">
    <div class="cp-footer">
      <div class="totals">
        <div class="sub-total">
          <span class="lbl">{{ 'HEADER.PREVIEW_CART.SUB_TOTAL' | translate }}</span>
          <span class="val">{{cart.total | price}}</span>
        </div>
        <ng-container *ngIf="cart.discounts && cart.discounts.length > 0">
          <div class="sub-total">
            <div class="lbl"
                 *ngFor="let discountItem of cart.discounts">{{ 'HEADER.PREVIEW_CART.DISCOUNT' | translate }}
              ({{discountItem.label}} - <span
                *ngIf="discountItem.value">{{discountItem.value}}</span>
              <span *ngIf="discountItem.percent">{{discountItem.percent}}%</span>)
            </div>
            <div class="val">-{{basicHelperService.getDiscountValue(cart.discounts, cart.total) | price}}</div>
          </div>
        </ng-container>
        <div class="sub-total">
          <span class="lbl">{{ 'HEADER.PREVIEW_CART.TAXES' | translate }} ({{tax}}%)</span>
          <div class="val">{{taxValue | price}}</div>
        </div>
        <div class="sub-total">
          <span class="lbl">{{ 'HEADER.PREVIEW_CART.SHIPPING' | translate }}</span>
          <span class="val">-</span>
        </div>
        <div class="big-total">
          <span class="lbl">{{ 'HEADER.PREVIEW_CART.TOTAL' | translate }}</span>
          <div class="val">{{bigTotal | price}}</div>
        </div>
      </div>
      <a class="neo-btn go-cart" href="/cart">{{ 'HEADER.PREVIEW_CART.GO_CART' | translate }}</a>
      <div class="checkout" (click)="goCheckout()">{{ 'HEADER.PREVIEW_CART.GO_CHECKOUT' | translate }}</div>
    </div>
  </ng-container>
</div>
