import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  Cart,
  MediaSize,
  MediaType,
  Menu,
  MenuItem,
  MenuService,
  ShopyanCartHelperService,
  StorageService,
  urlMedia
} from "@app/_shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";
import {isPlatformBrowser} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanHeaderComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  menuItems: MenuItem[] = [];

  displaySearch = false;
  displayCartContent = false;

  filter: string = '';

  isAuthenticated = false;
  isAuthEnabled = false;

  avatar = 'ab';

  public locales: any = [
    {name: "French", code: "fr", country: "fr"},
    {name: "English", code: "en", country: "gb"},
    {name: "العربية", code: "ar", country: "ma"},
    {name: "Deutsch", code: "de", country: "de"},
    {name: "Español", code: "es", country: "es"},
  ];

  selectedLang: any;


  protected constructor(private translate: TranslateService,
                        private router: Router,
                        private _sanitizer: DomSanitizer,
                        private storageService: StorageService,
                        private menuService: MenuService,
                        private basicHelperService: ShopyanCartHelperService,
                        private authService: AuthService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  init(): void {
    this.initMenuItems(this.getFieldsGroupValue('menu-block', 'menu'));
    this.isAuthenticated = this.authService.isAuthenticated();
    this.isAuthEnabled = this.authService.isAuthEnabled();
    if (this.isAuthenticated) {
      const user = this.authService.getConnectedUser();
      this.avatar = user.firstName?.toUpperCase()?.charAt(0) + user.lastName?.toUpperCase()?.charAt(0);
    }

    this.subscription.add(this.basicHelperService.cartSubject.subscribe((next: any) => {
      this.showCartPreview();
    }));

    //Languages
    let langList = this.storageService.getData('lang');
    if (langList) {
      langList = JSON.parse(langList);
    }
    this.locales = this.locales.filter((l: any) => langList && langList.indexOf(l.code) >= 0);
    const myLang = this.storageService.getData('my_lang');
    this.selectedLang = this.locales.find((l: any) => l.code === myLang);
  }

  protected abstract showCartPreview(): void;

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

  initMenuItems(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (id) {
        this.subscription.add(this.menuService.findMenu(id).subscribe((response: any) => {
          this.menuItems = response.menuItems;
          if (!this.menuItems || this.menuItems.length === 0) {
            this.menuItems = this.getDefaultMenu().menuItems;
          }
        }));
      } else {
        this.menuItems = this.getDefaultMenu().menuItems;
      }
    }
  }

  protected abstract getDefaultMenu(): Menu;


  search(): void {
    this.router.navigateByUrl(`/search?q=${this.filter}`).then();
  }

  get favoritesCount(): number {
    let wishList: string[] = [];
    const storageData = this.storageService.getData('wis');
    if (storageData) {
      wishList = JSON.parse(storageData);
    }
    if (!wishList || !(wishList instanceof Array)) {
      wishList = [];
    }
    return wishList?.length || 0;
  }

  get cartCount(): number {
    let cart = new Cart();
    const storageData = this.storageService.getData('car');
    if (storageData) {
      cart = JSON.parse(storageData);
    }
    return cart?.items?.length || 0;
  }

  getLink(menuItem: MenuItem): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.constructMenuLink(menuItem));
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
  }

  changeLanguage(lang: string) {
    this.selectedLang = this.locales.find((l: any) => l.code === lang);
    this.storageService.saveData("my_lang", lang);
    this.translate.use(lang);
    this.basicHelperService.langSubject.next("");
  }

  logout() {
    this.authService.logOut();
  }

}
