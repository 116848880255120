<div [id]="data.code" class="neo-express-checkout shopyan-section" [class.build-mode]="buildMode">
  <div class="neo-section-layout large">
    <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
    <div class="ex-container"
         [style.flex-direction]="getFieldsGroupValue('layout','flex-direction')">

      <div class="checkout-gallery"
           *ngIf="getFieldsGroupValue('layout','show-product-images') ">
        <div class="main-image" [class.default-bg]="!selectedMedia">
          <ng-container *ngIf="selectedMedia">
            <ng-container *ngIf="selectedMedia.isVideo == false">
              <img alt="main image" [lightbox]="lightBoxIndex" [src]="selectedMedia.link" loading="eager"/>
            </ng-container>
            <ng-container *ngIf="selectedMedia.isVideo == true">
              <iframe [src]="selectedMedia.link"
                      allowfullscreen [frameBorder]="0"></iframe>
            </ng-container>
          </ng-container>
        </div>
        <div class="other-images carousel-images" *ngIf="media && media.length > 1">
          <ngx-slick-carousel [config]="slideConfig" *ngIf="displayCarousel">
            <ng-container *ngFor="let item of media; let index = index;">
              <div class="image-item" ngxSlickItem>
                <ng-container *ngIf="item.isVideo == false">
                  <img alt="store" (click)="selectedMedia = item" [src]="getSmallImagePath(item.link)"/>
                </ng-container>
                <ng-container *ngIf="item.isVideo == true">
                  <img alt="store" (click)="selectedMedia = item" [src]="item.thumbnail"/>
                </ng-container>
              </div>
            </ng-container>
          </ngx-slick-carousel>
        </div>
      </div>

      <div class="checkout-container"
           [style.background-color]="getFieldsGroupValue('checkout','background-color')"
           [style.justify-content]="getFieldsGroupValue('layout','justify-content')"
           [style.align-items]="getFieldsGroupValue('layout','align-checkout')">
        <div class="checkout-content"
             *ngIf="product"
             [style.max-width]="getFieldsGroupValue('layout','width')+'px'">

          <div class="product-area" *ngIf="getFieldsGroupValue('checkout','show-product-data')">

            <div class="product-title">
              <div class="ttl"
                   [style.color]="getFieldsGroupValue('checkout','title-color')"
                   [style.font-size]="getFieldsGroupValue('checkout','title-size')+'px'">{{product.name}}</div>
            </div>

            <!-- PRICE -->
            <div class="product-price">
              <div class="prices">
                <div class="real-price"
                     [style.color]="getFieldsGroupValue('checkout','price-color')"
                     [class.with-shadow]="getFieldsGroupValue('checkout','price-with-shadow')"
                     [style.font-size]="getFieldsGroupValue('checkout','price-size')+'px'">
                  {{price - basicHelperService.getDiscountValue(discounts, price) | price}}
                </div>
                <div *ngIf="getFieldsGroupValue('checkout','hide-init-price') != true && basicPrice > 0"
                     [class.with-shadow]="getFieldsGroupValue('checkout','basic-with-shadow')"
                     [style.color]="getFieldsGroupValue('checkout','basic-price-color')"
                     [style.font-size]="getFieldsGroupValue('checkout','basic-price-size')+'px'"
                     class="init-price">
                  {{basicPrice| price}}
                </div>
                <div class="discount-percent" *ngIf="discountPercent > 0">-{{discountPercent}} %</div>
              </div>
            </div>

            <!-- RATE -->
            <div class="product-reviews" *ngIf="getFieldsGroupValue('checkout','hide-reviews') != true">
              <div class="rate neo-rate">
                <bar-rating [(rate)]="product.rate" [max]="5" [readOnly]="true"></bar-rating>
              </div>
              <div class="count">{{product.rateCount || 0}} {{'PRODUCT.REVIEWS' | translate}}</div>
            </div>

            <!-- DISCOUNT -->
            <div *ngIf="discounts" class="product-discounts">
              <ng-container *ngFor="let discount of discounts;">
                <div class="discount-item">
                  <span *ngIf="discount.value">{{discount.value | price}}</span>
                  <span *ngIf="discount.percent">{{discount.percent}}%</span>
                  {{discount.label}}
                  <ng-container *ngIf="discount.minimumRequirementQuantity">
                    <strong>({{'PRODUCT.DISCOUNT.MINIMUM_QUANTITY' | translate}} {{discount.minimumRequirementQuantity}}
                      )</strong>
                  </ng-container>
                  <ng-container *ngIf="discount.minimumRequirementAmount">
                    <strong>({{'PRODUCT.DISCOUNT.MINIMUM_AMOUNT' | translate}} {{discount.minimumRequirementAmount | price}}
                      )</strong>
                  </ng-container>
                </div>
              </ng-container>
            </div>

          </div>

          <div class="checkout-form-area"
               *ngIf="(product.variations && product.variations.length > 0) || product.stock > 0 || product.disableOutOfStock; else outOfStock">

            <div *ngIf="product && product.variations && product.variations.length > 0" class="product-options">
              <div id="product-options-anchor" class="anchor"></div>
<!--              <div class="option-ttl">{{'PRODUCT.OPTIONS' | translate}}</div>-->
              <ng-container *ngIf="getFieldsGroupValue('checkout','variation-mode') != 'select'; else selectVariation">
                <div class="option-area" *ngFor="let option of product.options; let index = index">
                  <div class="oa-ttl">{{option.name}}</div>
                  <div class="oa-items">
                    <ng-container *ngIf="option.type == variationOptionType.COLOR">
                      <div class="option-color"
                           [class.disabled]="isOptionDisabled(optionValue.value, index)"
                           [class.checked]="selectedOptions[index] == optionValue.value"
                           (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                           *ngFor="let optionValue of option.optionValues">
                        <div class="color-content" [style.background-color]="optionValue.color"></div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="option.type == variationOptionType.IMAGE">
                      <div class="option-image"
                           [class.disabled]="isOptionDisabled(optionValue.value, index)"
                           [class.checked]="selectedOptions[index] == optionValue.value"
                           (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                           *ngFor="let optionValue of option.optionValues">
                        <img *ngIf="optionValue.media" [src]="urlVariantSmallMedia(optionValue.media)"/>
                        <img *ngIf="!optionValue.media" src="/assets/img/neo-picture.svg"/>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!option.type || option.type == variationOptionType.TEXT">
                      <div class="option-value"
                           [class.disabled]="isOptionDisabled(optionValue.value, index)"
                           [class.checked]="selectedOptions[index] == optionValue.value"
                           (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                           *ngFor="let optionValue of option.optionValues">{{optionValue.value}}</div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-template #selectVariation>
                <mat-form-field appearance="outline">
                  <mat-label>{{'PRODUCT.CHOOSE_OPTION' | translate}}</mat-label>
                  <mat-select (selectionChange)="changeVariation()" [(ngModel)]="selectedVariation">
                    <ng-container *ngFor="let variation of product.variations">
                      <mat-option
                        [disabled]="variation.disabled || (!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0))"
                        [value]="variation.id">
                        <span>{{variation.optionValues.join(' - ') + ' - '}} {{variation.price - basicHelperService.getDiscountValue(variation.discounts, variation.price) | price}}</span>
                        <span
                          *ngIf="!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0)">({{'PRODUCT.OUT_OF_STOCK_TITLE' | translate}}
                          )</span>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </ng-template>
            </div>

            <!-- EXPRESS CHECKOUT -->
            <ng-container *ngIf="expressFG">
              <div class="cod-form">
                <div id="cod-form-anchor" class="anchor"></div>
                <div [formGroup]="expressFG" class="cod-form-content"
                     [style.border-color]="getFieldsGroupValue('checkout','express-border-color')"
                     [style.border-width]="getFieldsGroupValue('checkout','express-border-width')+'px'"
                     [style.border-radius]="getFieldsGroupValue('checkout','express-border-radius')+'px'"
                     [style.padding]="getFieldsGroupValue('checkout','express-padding')+'px'"
                     [style.background-color]="getFieldsGroupValue('checkout','express-background-background-color')">
                  <div *ngIf="checkoutLabel" class="form-intro ql-container ql-snow">
                    <div class="ql-editor" [innerHTML]="checkoutLabel"></div>
                  </div>
                  <div class="form-fields">
                    <ng-container formArrayName="fields">
                      <ng-container *ngFor="let field of checkoutFields; let index = index">
                        <ng-container
                          *ngIf="field.subType != checkoutFieldSubType.HIDDEN">
                          <ng-container [formGroupName]="index">
                            <div class="neo-field"
                                 [class.select-border]="!!getFieldsGroupValue('checkout','input-border-color')">
                              <ng-container [ngSwitch]="field.type">
                                <ng-container *ngSwitchCase="checkoutFieldType.PHONE">
                                  <input
                                    [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                    [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                    [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                    [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                    [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                    [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                    [style.line-height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                    [style.height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                    InputValidator type="tel" class="neo-input" [placeholder]="field.label"
                                    formControlName="value"/>
                                </ng-container>
                                <ng-container *ngSwitchCase="field.type == checkoutFieldType.ADDRESS_1 ||
                                                     field.type == checkoutFieldType.ADDRESS_2 ||
                                                     field.type == checkoutFieldType.COMMENT">
                                  <textarea
                                    [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                    [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                    [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                    [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                    [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                    [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                    InputValidator class="neo-input" [placeholder]="field.label"
                                    formControlName="value"></textarea>
                                </ng-container>
                                <ng-container *ngSwitchCase="checkoutFieldType.CUSTOM_FIELD">
                                  <ng-container [ngSwitch]="field.subType">
                                    <ng-container *ngSwitchCase="checkoutFieldSubType.INPUT">
                                      <input
                                        [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                        [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                        [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                        [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                        [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                        [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                        [style.line-height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                        [style.height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                        InputValidator type="text" class="neo-input" [placeholder]="field.label"
                                        formControlName="value"/>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="checkoutFieldSubType.NUMBER">
                                      <input
                                        [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                        [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                        [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                        [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                        [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                        [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                        [style.line-height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                        [style.height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                        InputValidator type="number" class="neo-input" [placeholder]="field.label"
                                        formControlName="value"/>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="checkoutFieldSubType.TEXTAREA">
                                       <textarea
                                         [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                         [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                         [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                         [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                         [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                         [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                         InputValidator class="neo-input" [placeholder]="field.label"
                                         formControlName="value"></textarea>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="checkoutFieldSubType.SELECT">
                                      <select class="neo-input" formControlName="value"
                                              [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                              [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                              [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                              [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                              [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                              [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                              [style.line-height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                              [style.height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                      >
                                        <option [value]="''">{{field.label}}</option>
                                        <option [value]="item" *ngFor="let item of field.options">
                                          {{item}}
                                        </option>
                                      </select>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <input
                                    [style.border-width]="getFieldsGroupValue('checkout','express-field-border-width')+'px'"
                                    [style.font-size]="getFieldsGroupValue('checkout','express-field-size')+'px'"
                                    [style.border-color]="getFieldsGroupValue('checkout','express-field-border-color')"
                                    [style.background-color]="getFieldsGroupValue('checkout','express-field-background-color')"
                                    [style.color]="getFieldsGroupValue('checkout','express-field-color')"
                                    [style.border-radius]="getFieldsGroupValue('checkout','express-field-border-radius')+'px'"
                                    [style.line-height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                    [style.height]="getFieldsGroupValue('checkout','express-field-height')+'px'"
                                    InputValidator
                                    type="text" class="neo-input" [placeholder]="field.label"
                                    formControlName="value"/>
                                </ng-container>
                                <app-field-error controlName="value" errorKey="required">
                                  {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
                                </app-field-error>
                                <app-field-error controlName="value" errorKey="pattern">
                                  {{'COMMON.ERROR.INVALID_VALUE' | translate}}
                                </app-field-error>
                              </ng-container>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="buy-actions">
                <div id="product-quantity-anchor" class="anchor"></div>
                <div class="quantity" *ngIf="getFieldsGroupValue('checkout','hide-quantity') != true">
                  <lib-neo-quantity [(value)]="quantity" [min]="1" [max]="maxQuantity"></lib-neo-quantity>
                </div>
                <button (click)="submitExpressCheckout()"
                        [class.shake-btn]="getFieldsGroupValue('checkout','shake-btn') == true"
                        [loading]="formLoading"
                        [style.border-radius]="getFieldsGroupValue('checkout','buy-border-radius')+'px'"
                        [style.color]="getFieldsGroupValue('checkout','buy-color')"
                        [style.background-color]="getFieldsGroupValue('checkout','buy-bg-color')"

                        [style.line-height]="getFieldsGroupValue('checkout','buy-height')+'px'"
                        [style.font-size]="getFieldsGroupValue('checkout','buy-font-size')+'px'"
                        [class.lower]="getFieldsGroupValue('checkout','buy-lower') == true"
                        [class.with-ic]="getFieldsGroupValue('checkout','buy-show-icon') == true"
                        class="neo-btn buy-now"
                        loadingBtn>
                  <div class="ic" *ngIf="getFieldsGroupValue('checkout','buy-show-icon') == true"></div>
                  <span>{{ getFieldsGroupValue('checkout', 'buy-label') || 'PRODUCT.BUY_NOW' | translate}}</span>
                </button>
              </div>
            </ng-container>

            <!-- CLASSIC CHECKOUT -->
            <div class="buy-actions" *ngIf="!expressFG">
              <div id="product-quantity-anchor" class="anchor"></div>
              <div class="quantity">
                <lib-neo-quantity [(value)]="quantity" [min]="1" [max]="maxQuantity"></lib-neo-quantity>
              </div>
              <button (click)="submitClassicCheckout()"
                      [loading]="formLoading" loadingBtn
                      [style.border-radius]="getFieldsGroupValue('checkout','buy-border-radius')+'px'"
                      [class.shake-btn]="getFieldsGroupValue('checkout','shake-btn') == true"
                      [style.color]="getFieldsGroupValue('checkout','buy-color')"
                      [style.background-color]="getFieldsGroupValue('checkout','buy-bg-color')"

                      [style.line-height]="getFieldsGroupValue('checkout','buy-height')+'px'"
                      [style.font-size]="getFieldsGroupValue('checkout','buy-font-size')+'px'"
                      [class.lower]="getFieldsGroupValue('checkout','buy-lower') == true"
                      [class.with-ic]="getFieldsGroupValue('checkout','buy-show-icon') == true"
                      class="neo-btn buy-now">
                <div class="ic" *ngIf="getFieldsGroupValue('checkout','buy-show-icon') == true"></div>
                <span>{{ getFieldsGroupValue('checkout', 'buy-label') || 'PRODUCT.BUY_NOW' | translate}}</span>
              </button>
            </div>

          </div>

          <ng-template #outOfStock>
            <div class="out-of-stock">
              <div class="big-ttl">{{'PRODUCT.OUT_OF_STOCK_TITLE' | translate}}</div>
              <div class="desc">{{'PRODUCT.OUT_OF_STOCK_DESC' | translate}}</div>
            </div>
          </ng-template>

        </div>
      </div>

    </div>
  </div>
</div>


<ng-container
  *ngIf="product && ((product.variations && product.variations.length > 0) || product.stock > 0 || product.disableOutOfStock)">
  <div class="fixed-bar" *ngIf="getFieldsGroupValue('checkout','show-fixed-bar') == true">
    <div class="fb-content">

      <button (click)="submitClassicCheckout()" *ngIf="!expressFG"
              [loading]="formLoading" loadingBtn
              [style.border-radius]="getFieldsGroupValue('checkout','fixed-btn-border-radius')+'px'"
              [style.font-size]="getFieldsGroupValue('checkout','fixed-btn-font-size')+'px'"
              [style.line-height]="getFieldsGroupValue('checkout','fixed-btn-line-height')+'px'"
              [class.shake-btn]="getFieldsGroupValue('checkout','shake-btn') == true"
              [style.color]="getFieldsGroupValue('checkout','buy-color')"
              [style.background-color]="getFieldsGroupValue('checkout','buy-bg-color')"
              [class.with-ic]="getFieldsGroupValue('checkout','buy-show-icon') == true"
              [class.lower]="getFieldsGroupValue('checkout','buy-lower') == true"
              class="neo-btn buy-now">
        <div class="ic" *ngIf="getFieldsGroupValue('checkout','buy-show-icon') == true"></div>
        <span>{{ getFieldsGroupValue('checkout', 'buy-label') || 'PRODUCT.BUY_NOW' | translate}}</span>
      </button>
      <button (click)="submitExpressCheckout()"
              *ngIf="expressFG"
              [loading]="formLoading"
              [style.border-radius]="getFieldsGroupValue('checkout','fixed-btn-border-radius')+'px'"
              [style.font-size]="getFieldsGroupValue('checkout','fixed-btn-font-size')+'px'"
              [style.line-height]="getFieldsGroupValue('checkout','fixed-btn-line-height')+'px'"
              [class.shake-btn]="getFieldsGroupValue('checkout','shake-btn') == true"
              [style.color]="getFieldsGroupValue('checkout','buy-color')"
              [style.background-color]="getFieldsGroupValue('checkout','buy-bg-color')"
              [class.with-ic]="getFieldsGroupValue('checkout','buy-show-icon') == true"
              [class.lower]="getFieldsGroupValue('checkout','buy-lower') == true"
              class="neo-btn buy-now" loadingBtn>
        <div class="ic" *ngIf="getFieldsGroupValue('checkout','buy-show-icon') == true"></div>
        <span>{{ getFieldsGroupValue('checkout', 'buy-label') || 'PRODUCT.BUY_NOW' | translate }}</span>
      </button>

    </div>
  </div>
</ng-container>
