import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StorePageComponent} from '@app/store-page/store-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {StoreHttpInterceptor} from "@app/_shared/interceptor/store-http.interceptor";
import {SalesPopComponent} from '@app/store-apps/sales-pop/sales-pop.component';
import {NotFoundComponent} from "@app/error-page/not-found/not-found.component";
import {InternalErrorComponent} from "@app/error-page/internal-error/internal-error.component";
import {MaintenanceComponent} from "@app/error-page/maintenance/maintenance.component";
import {AccessDeniedComponent} from "@app/error-page/access-denied/access-denied.component";
import {
  AddToCartDirective,
  FieldErrorComponent,
  HasErrorsDirective,
  LoadingButtonComponent,
  NeoAccountComponent,
  NeoAccountFormComponent,
  NeoAddressFormComponent,
  NeoAnnouncementBarComponent,
  NeoCancelComponent,
  NeoCarouselComponent,
  NeoCartComponent,
  NeoCartPreviewComponent,
  NeoCheckoutClassicComponent,
  NeoCheckoutComponent,
  NeoCollageComponent,
  NeoCollageItemComponent,
  NeoCollapsibleContentComponent,
  NeoCollectionComponent,
  NeoCollectionListComponent,
  NeoConfirmationDialogComponent,
  NeoContactFormComponent,
  NeoCountdownComponent,
  NeoEditorComponent,
  NeoExpressBtnComponent,
  NeoExpressCheckoutComponent,
  NeoFavoritesComponent,
  NeoFeaturedCollectionsComponent,
  NeoFeaturedProductsComponent,
  NeoFooterComponent,
  NeoForgotPasswordComponent,
  NeoHeaderComponent,
  NeoImageBannerComponent,
  NeoImageComponent,
  NeoImagesComponent,
  NeoImageTextComponent,
  NeoIndicatorComponent,
  NeoIndicatorItemComponent,
  NeoLandingPageProductDescComponent,
  NeoLoginComponent, NeoMenuItemComponent,
  NeoMultiColumnsComponent,
  NeoMyCollectionsComponent,
  NeoMyProductsComponent,
  NeoNewsletterComponent,
  NeoNoResultComponent,
  NeoOrdersComponent,
  NeoOrderSummaryComponent,
  NeoOrderSummarySharedComponent,
  NeoPageContentComponent,
  NeoProductCardComponent,
  NeoProductCheckoutComponent,
  NeoProductComponent,
  NeoProductListComponent,
  NeoProductReviewsComponent,
  NeoQuantityComponent,
  NeoRegisterComponent,
  NeoRichTextComponent,
  NeoSectionHeaderComponent,
  NeoSeparatorComponent,
  NeoStoreReviewsComponent,
  NeoTextBannerComponent,
  NeoThankYouComponent,
  NeoUpsellModalComponent,
  NeoUpsellMonoProductComponent,
  NeoVideoComponent,
  NeoYouMayProductsComponent,
  PricePipe,
  ShopyanPlaceholderComponent,
  ShoypanCountDownComponent,
  NeoTreeComponent
} from "@app/_shared";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CountdownModule} from "ngx-countdown";
import {ShareModule} from "ngx-sharebuttons";
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";
import {NgOptimizedImage} from "@angular/common";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslationLoaderService} from "@app/translate-loader.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatRadioModule} from "@angular/material/radio";
import {MatMenuModule} from "@angular/material/menu";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BarRatingModule} from "ngx-bar-rating";
import {LightboxModule} from "ng-gallery/lightbox";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {MatSelectModule} from "@angular/material/select";
import {WhatsappComponent} from './store-apps/whatsapp/whatsapp.component';
import {GlobalErrorHandlerService} from "@app/_shared/interceptor/gobal-error-handler.service";
import {MatButtonModule} from "@angular/material/button";
import {MatTreeModule} from "@angular/material/tree";

const neoComponents = [
  NeoHeaderComponent,
  NeoFooterComponent,
  NeoCarouselComponent,
  NeoEditorComponent,
  NeoImageBannerComponent,
  NeoYouMayProductsComponent,
  NeoThankYouComponent,
  NeoVideoComponent,
  NeoPageContentComponent,
  NeoNewsletterComponent,
  NeoFeaturedProductsComponent,
  NeoFeaturedCollectionsComponent,
  NeoFavoritesComponent,
  NeoProductComponent,
  NeoProductCardComponent,
  NeoNoResultComponent,
  NeoSectionHeaderComponent,
  NeoCollageComponent,
  NeoCollageItemComponent,
  NeoMyProductsComponent,
  NeoMyCollectionsComponent,
  NeoAnnouncementBarComponent,
  NeoImageTextComponent,
  NeoContactFormComponent,
  NeoRichTextComponent,
  NeoImageComponent,
  NeoImagesComponent,
  NeoMultiColumnsComponent,
  NeoCollapsibleContentComponent,
  NeoStoreReviewsComponent,
  NeoCollectionComponent,
  NeoCollectionListComponent,
  NeoProductListComponent,
  NeoCartComponent,
  NeoQuantityComponent,
  NeoCartPreviewComponent,
  NeoProductReviewsComponent,
  NeoCancelComponent,
  NeoCheckoutComponent,
  NeoCheckoutClassicComponent,
  NeoUpsellModalComponent,
  NeoUpsellMonoProductComponent,
  NeoOrderSummaryComponent,
  NeoLoginComponent,
  NeoRegisterComponent,
  NeoForgotPasswordComponent,
  NeoOrdersComponent,
  NeoAccountComponent,
  NeoAccountFormComponent,
  NeoAddressFormComponent,
  NeoConfirmationDialogComponent,
  NeoOrderSummarySharedComponent,
  NeoProductCheckoutComponent,
  NeoExpressCheckoutComponent,
  NeoLandingPageProductDescComponent,
  NeoCountdownComponent,
  NeoSeparatorComponent,
  NeoIndicatorComponent,
  NeoIndicatorItemComponent,
  NeoTextBannerComponent,
  NeoExpressBtnComponent
]


@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CountdownModule,
    ShareModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService
      }
    }),
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatFormFieldModule,
    LightboxModule,
    BarRatingModule,
    SlickCarouselModule,
    MatSelectModule,
    MatButtonModule,
    MatTreeModule
  ],
  declarations: [
    AppComponent,
    StorePageComponent,
    NotFoundComponent,
    MaintenanceComponent,
    AccessDeniedComponent,
    InternalErrorComponent,
    SalesPopComponent,
    FieldErrorComponent,
    LoadingButtonComponent,
    ShoypanCountDownComponent,
    AddToCartDirective,
    HasErrorsDirective,
    PricePipe,
    neoComponents,
    ShopyanPlaceholderComponent,
    WhatsappComponent,
    NeoMenuItemComponent,
    NeoTreeComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    TranslationLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StoreHttpInterceptor,
      multi: true
    },
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService,
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
