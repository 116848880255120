import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AppsService,
  AuthService,
  CommonService,
  ComponentLookup, ProductService,
  ShopyanCartHelperService,
  ShopyanProductComponent,
  ShopyanToastrService,
  StorageService, StoreService,
  UpsellService
} from '@app/_shared';
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {Gallery, GalleryItem, ImageItem} from "ng-gallery";
import {Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-product',
  templateUrl: './neo-product.component.html',
  styleUrls: ['./neo-product.component.scss']
})
@ComponentLookup('neo-product')
export class NeoProductComponent extends ShopyanProductComponent implements OnInit, OnDestroy {

  galleryItem: GalleryItem[] = [];

  slideConfig: any = {
    vertical: true,
    verticalSwiping: true,
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "infinite": false
  };

  slideConfig2: any = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false
  };

  displayCarousel = true;

  displayMainCarousel: boolean;

  constructor(translate: TranslateService,
              _sanitizer: DomSanitizer,
              public gallery: Gallery,
              router: Router,
              commonService: CommonService,
              productService: ProductService,
              storeService: StoreService,
              upsellService: UpsellService,
              storageService: StorageService,
              authService: AuthService,
              shopyanCartHelperService: ShopyanCartHelperService,
              toastr: ShopyanToastrService,
              dialog: MatDialog,
              formBuilder: FormBuilder,
              shopyanAppsService: AppsService,
              private shopyanAppsServiced: AppsService,
              http: HttpClient,
               conversionService: ConversionService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any,
              deviceDetectorService: DeviceDetectorService) {
    super(translate, _sanitizer, upsellService, commonService, productService, storeService, router, storageService, authService, dialog, shopyanCartHelperService,
      toastr, formBuilder, shopyanAppsService, http, conversionService, transferState, platformId, deviceDetectorService);
  }

  ngOnInit(): void {
    this.init();
    this.initLTR();
    this.subscription.add(this.basicHelperService.langSubject.subscribe((next: any) => {
      this.displayCarousel = false;
      this.initLTR();
      setTimeout(() => {
        this.displayCarousel = true;
      }, 300);
    }));

    if(isPlatformBrowser(this.platformId)){
      this.displayMainCarousel = window.innerWidth < 550;
    }
  }

  initLTR(): void {
    this.slideConfig2.rtl = this.translate.currentLang === 'ar';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.displayMainCarousel = window.innerWidth < 550;
  }

  /**
   * init gallery
   */
  initGallery(): void {

    //Load the light box
    this.media?.forEach(m => {
      if (m.isVideo === false) {
        this.galleryItem.push(new ImageItem({src: m.link, thumb: m.link}));
      }
    });

    const lightboxRef = this.gallery.ref();
    // Load items into the lightbox gallery ref
    lightboxRef.load(this.galleryItem);
  }


  /**
   * Lightbox index
   */
  get lightBoxIndex(): number {
    return this.galleryItem.indexOf(this.galleryItem.find(item => item.data.src === this.selectedMedia.link)!);
  }


  get discountPercent(): number {
    if (this.basicPrice > 0) {
      return Math.floor(100 * (this.basicPrice - (this.price - this.basicHelperService.getDiscountValue(this.discounts, this.price))) / this.product.pricing.basicPrice);
    }
    return 0;
  }

  isLandingComponent(): boolean {
    return false;
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected readonly window = window;
}
