<div [id]="data.code" class="neo-checkout shopyan-section"
     [class.build-mode]="buildMode">
  <div class="neo-section-layout">
    <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
    <ng-template #noResult>
      <lib-neo-no-result text="{{'CHECKOUT.EMPTY' | translate}}"></lib-neo-no-result>
    </ng-template>

    <div class="checkout-area"
         *ngIf="!loading && checkoutData && checkoutData.cart && checkoutData.cart.items && checkoutData.cart.items.length  > 0; else noResult">
      <div class="buyer-details">

        <div class="bd-content">
          <div class="checkout-big-ttl">{{'CHECKOUT.TITLE' | translate}}</div>

          <div class="bd-section">
            <div class="bds-title">
              <span class="ttl">{{'CHECKOUT.DETAILS' | translate}}</span>
            </div>
            <div class="bds-content">

              <!-- EXPRESS CHECKOUT -->
              <ng-container [formGroup]="expressFG" *ngIf="this.expressFG; else classicCheckout">
                <ng-container formArrayName="fields">
                  <div class="fields">
                    <ng-container *ngFor="let field of checkoutData.checkoutFields; let index = index">
                      <ng-container
                        *ngIf="field.subType != checkoutFieldSubType.HIDDEN">
                        <ng-container [formGroupName]="index">
                          <div class="neo-field">
                            <div class="neo-field-label">
                              <label class="neo-label">
                                <span>{{field.label}}</span>
                                <span class="mandatory" *ngIf="field.required">*</span>
                              </label>
                            </div>
                            <ng-container [ngSwitch]="field.type">
                              <ng-container *ngSwitchCase="checkoutFieldType.PHONE">
                                <input InputValidator type="tel" class="neo-input" [placeholder]="field.label"
                                       formControlName="value"/>
                              </ng-container>
                              <ng-container *ngSwitchCase="field.type == checkoutFieldType.ADDRESS_1 ||
                                                     field.type == checkoutFieldType.ADDRESS_2 ||
                                                     field.type == checkoutFieldType.COMMENT">
                                <textarea InputValidator class="neo-input" [placeholder]="field.label"
                                          formControlName="value"></textarea>
                              </ng-container>
                              <ng-container *ngSwitchCase="checkoutFieldType.CUSTOM_FIELD">
                                <ng-container [ngSwitch]="field.subType">
                                  <ng-container *ngSwitchCase="checkoutFieldSubType.INPUT">
                                    <input InputValidator type="text" class="neo-input" [placeholder]="field.label"
                                           formControlName="value"/>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="checkoutFieldSubType.NUMBER">
                                    <input InputValidator type="number" class="neo-input" [placeholder]="field.label"
                                           formControlName="value"/>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="checkoutFieldSubType.TEXTAREA">
                                     <textarea InputValidator class="neo-input" [placeholder]="field.label"
                                               formControlName="value"></textarea>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="checkoutFieldSubType.SELECT">
                                    <mat-form-field appearance="outline">
                                      <mat-select formControlName="value">
                                        <mat-option [value]="''">{{field.label}}</mat-option>
                                        <ng-container *ngFor="let item of field.options">
                                          <mat-option [value]="item">
                                            {{item}}
                                          </mat-option>
                                        </ng-container>
                                      </mat-select>
                                    </mat-form-field>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                <input InputValidator type="text" class="neo-input" [placeholder]="field.label"
                                       formControlName="value"/>
                              </ng-container>
                              <app-field-error controlName="value" errorKey="required">
                                {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
                              </app-field-error>
                              <app-field-error controlName="value" errorKey="pattern">
                                {{'COMMON.ERROR.INVALID_VALUE' | translate}}
                              </app-field-error>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <!-- CLASSIC CHECKOUT -->
              <ng-template #classicCheckout>
                <lib-neo-checkout-classic
                  [checkoutForm]="checkoutForm"
                  [shippingAddressFG]="shippingAddressFG"
                  [billingAddressFG]="billingAddressFG"
                  [customer]="checkoutData.customer"
                  [storeAccountMode]="checkoutData.storeAccountMode"></lib-neo-checkout-classic>
              </ng-template>

            </div>
          </div>

          <div class="bd-section" *ngIf="checkoutData.shippingItems && checkoutData.shippingItems.length > 0">
            <div class="bds-title">
              <span class="ttl">{{'CHECKOUT.SHIPPING_DETAILS' | translate}}</span>
            </div>
            <div class="bds-content">
              <div class="neo-field">
                <div class="neo-field-label">
                  <label class="neo-label">
                    <span>{{'CHECKOUT.SHIPPING_SELECT' | translate}}</span>
                    <span class="mandatory">*</span>
                  </label>
                </div>
                <mat-form-field appearance="outline">
                  <mat-select [(ngModel)]="selectedShipping">
                    <ng-container *ngFor="let item of checkoutData.shippingItems">
                      <mat-option [value]="item">{{item.label}}
                        <ng-container *ngIf="item.price">: {{item.price | price }}</ng-container>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="order-details">
        <div class="od-content">
          <div class="od-items">
            <div class="od-item" *ngFor="let item of checkoutData.cart.items;">
              <div class="pic">
                <img alt="store" [src]="urlProductMediumMedia(item.product.medias[0])"
                     *ngIf="!item.variation && item.product.medias && item.product.medias.length > 0"/>
                <img alt="store" [src]="urlVariantMediumMedia(item.variation.media)"
                     *ngIf="item.variation && item.variation.media"/>
                <img alt="store" [src]="item.product.imageLinks[0]"
                     *ngIf="(!item.product.medias || item.product.medias.length == 0) && (!item.variation || !item.variation.media)
                    && item.product.imageLinks && item.product.imageLinks.length > 0"/>

                <div class="quantity">{{item.quantity}}</div>
              </div>
              <div class="product-data">
                <a href="/product/{{item.product.slug}}" class="product-name">{{item.product.name}}</a>
                <div class="product-variations" *ngIf="item.variation">
                  <ng-container *ngFor="let option of item.variation.optionValues; let index = index">
                    <div class="variation">{{option}}</div>
                    <div class="sep" *ngIf="index < (item.variation.optionValues.length - 1 )">-</div>
                  </ng-container>
                </div>
                <div class="product-discounts" *ngIf="item.discounts || item.upsell">
                  <ng-container
                    *ngIf="item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent)">
                    <div>
                      <div class="discount">
                        -<span *ngIf="item.upsell.fixedDiscount">{{item.upsell.fixedDiscount | price }}</span>
                        <span *ngIf="item.upsell.discountPercent">{{item.upsell.discountPercent}}%</span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let discount of item.discounts; let index = index">
                    <div>
                      <div class="discount">
                        -<span *ngIf="discount.value">{{discount.value | price }}</span>
                        <span *ngIf="discount.percent">{{discount.percent}}%</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="product-price">
                <div class="final-price"
                     [class.discount]="item.discounts && item.discounts.length != 0 || (item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent))">
                  <span>{{item.total || 0 | price }}</span></div>
                <div class="product-discounted"
                     *ngIf="(item.discounts && item.discounts.length) != 0 || (item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent))">{{basicHelperService.getDiscountedPrice(item) | price }}</div>
              </div>
            </div>
          </div>
          <div class="od-sub-totals">
            <div class="sub-total-item">
              <div class="lbl">{{'CHECKOUT.SUB_TOTAL' | translate}}</div>
              <div class="val">{{checkoutData.cart.total < 0 ? 0 : checkoutData.cart.total | price }}</div>
            </div>
            <ng-container *ngIf="checkoutData.cart.discounts && checkoutData.cart.discounts.length > 0">
              <div class="sub-total-item">
                <div class="lbl"
                     *ngFor="let discountItem of checkoutData.cart.discounts">{{'CHECKOUT.DISCOUNT' | translate}}
                  ({{discountItem.label}} - <span *ngIf="discountItem.value">{{discountItem.value}}</span>
                  <span *ngIf="discountItem.percent">{{discountItem.percent}}%</span>)
                </div>
                <div class="val">
                  -{{basicHelperService.getDiscountValue(checkoutData.cart.discounts, checkoutData.cart.total) | price }}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="appliedDiscount">
              <div class="sub-total-item">
                <div class="lbl">{{'CHECKOUT.DISCOUNT' | translate}} ({{appliedDiscount.label}} -
                  <span *ngIf="appliedDiscount.value">{{appliedDiscount.value || 0 | price}}</span>
                  <span *ngIf="appliedDiscount.percent">{{appliedDiscount.percent}}%</span>)
                </div>
                <div class="val">
                  -{{basicHelperService.getDiscountValue([appliedDiscount], checkoutData.cart.total) | price }}</div>
              </div>
            </ng-container>
            <div class="sub-total-item" *ngIf="selectedShipping">
              <div class="lbl">{{'CHECKOUT.SHIPPING' | translate}}</div>
              <div class="val">{{selectedShipping.price || 0}}</div>
            </div>
            <div class="sub-total-item" *ngIf="checkoutData.tax">
              <div class="lbl">{{'CHECKOUT.TAX' | translate}} ({{checkoutData.tax}}%)</div>
              <div class="val">{{taxValue | price }}</div>
            </div>
          </div>
          <div class="od-total">
            <div class="lbl">{{'CHECKOUT.TOTAL' | translate}}</div>
            <div class="val">{{bigTotal | price }}</div>
          </div>

          <div class="od-payment-mode">
            <div class="neo-field">
              <div class="neo-field-label">
                <label class="neo-label">{{'CHECKOUT.PAYMENT.TITLE' | translate}}</label>
              </div>
              <div class="payment-form">
                <ng-container *ngFor="let method of checkoutData.paymentMethods">
                  <mat-radio-group [(ngModel)]="checkoutForm.paymentMethod">
                    <ng-container [ngSwitch]="method">
                      <div class="pm-item" *ngSwitchCase="paymentMethod.CASH_ON_DELIVERY">
                        <label class="pmi-label">
                          <mat-radio-button [value]="paymentMethod.CASH_ON_DELIVERY">
                            {{'CHECKOUT.PAYMENT.COD' | translate}}
                          </mat-radio-button>
                        </label>
                        <div class="pmi-note"> {{'CHECKOUT.PAYMENT.COD_DESC' | translate}}</div>
                      </div>
                      <div class="pm-item" *ngSwitchCase="paymentMethod.PAYPAL">
                        <label class="pmi-label">
                          <mat-radio-button
                            [value]="paymentMethod.PAYPAL">{{'CHECKOUT.PAYMENT.PAYPAL' | translate}}
                          </mat-radio-button>
                        </label>
                        <div class="pmi-note"> {{'CHECKOUT.PAYMENT.PAYPAL_DESC' | translate}}</div>
                        <div class="pmi-det paypal"></div>
                      </div>
                      <div class="pm-item" *ngSwitchCase="paymentMethod.CREDIT_CARD">
                        <label class="pmi-label">
                          <mat-radio-button
                            [value]="paymentMethod.CREDIT_CARD"> {{'CHECKOUT.PAYMENT.CARD' | translate}}
                          </mat-radio-button>
                        </label>
                        <div class="pmi-note"> {{'CHECKOUT.PAYMENT.CARD_DESC' | translate}}</div>
                        <div class="pmi-det cb"></div>
                      </div>
                    </ng-container>
                  </mat-radio-group>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="od-discount">
            <div class="neo-field">
              <div class="neo-field-label">
                <label class="neo-label">{{'CHECKOUT.APPLY_DISCOUNT' | translate}}</label>
              </div>
              <div class="discount-form">
                <input type="text" class="neo-input" [(ngModel)]="discountCode"
                       placeholder="{{'CHECKOUT.PROMO_CODE' | translate}}"/>
                <div class="neo-btn reverse" (click)="applyDiscount()">{{'CHECKOUT.APPLY' | translate}}</div>
              </div>
              <label class="neo-label error-item" *ngIf="discountError">{{discountError}}</label>
            </div>
          </div>
          <div class="od-validation">
            <button loadingBtn class="neo-btn"
                    [loading]="checkoutLoading" (click)="submitCheckout()">{{'CHECKOUT.ORDER' | translate}}
            </button>
          </div>
        </div>
        <fieldset class="od-payment">
          <legend>{{'CHECKOUT.SAFE_CHECKOUT' | translate}}</legend>
          <div class="icons">
            <div class="i-item mastercard"></div>
            <div class="i-item visa"></div>
            <div class="i-item stripe"></div>
            <div class="i-item paypal"></div>
            <div class="i-item amex"></div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>
