<div [id]="data.code" class="neo-landing-desc shopyan-section" [class.build-mode]="buildMode"
     [style.background]="getFieldValue('background')">

  <div class="neo-section-layout v-padding ql-editor"
       [class.large]="getFieldValue('layout-width') == 'large'">

    <div [innerHtml]="htmlText" *ngIf="htmlText"></div>

  </div>
</div>
