<div [class.build-mode]="buildMode" [id]="data.code"
     class="neo-product shopyan-section">
  <div class="neo-section-layout v-padding">
    <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
    <div class="product-content" *ngIf="product">

      <!-- MEDIA PART -->
      <div class="media-part">
        <div class="media-container">
          <div class="other-images carousel-images" *ngIf="media && media.length > 1 && !displayMainCarousel">
            <ngx-slick-carousel [config]="slideConfig" *ngIf="displayCarousel">
              <ng-container *ngFor="let item of media; let index = index;">
                <div class="image-item" ngxSlickItem>
                  <ng-container *ngIf="item.isVideo == false">
                    <img alt="store" (click)="selectedMedia = item" [src]="getSmallImagePath(item.link)"
                         loading="lazy"/>
                  </ng-container>
                  <ng-container *ngIf="item.isVideo == true">
                    <img alt="store" (click)="selectedMedia = item" [src]="item.thumbnail"/>
                  </ng-container>
                </div>
              </ng-container>
            </ngx-slick-carousel>
          </div>
          <div class="main-image">
            <div class="product-actions">
              <div (click)="toggleToWishList(product.id)" [class.checked]="isProductWishListChecked(product.id)"
                   class="wishlist"></div>
            </div>
            <ng-container *ngIf="!displayMainCarousel">
              <ng-container *ngIf="selectedMedia">
                <ng-container *ngIf="selectedMedia.isVideo == false">
                  <img [alt]="product.name" [lightbox]="lightBoxIndex" [src]="selectedMedia.link" priority/>
                </ng-container>
                <ng-container *ngIf="selectedMedia.isVideo == true">
                  <iframe [src]="selectedMedia.link"
                          allowfullscreen [frameBorder]="0"></iframe>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!selectedMedia">
                <img [alt]="product.name" ngSrc="assets/img/neo-bloc-3.webp" priority width="466" height="348"/>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="displayMainCarousel">
              <ngx-slick-carousel [config]="slideConfig2" #slickModal="slick-carousel" *ngIf="displayCarousel">
                <ng-container *ngFor="let item of media;">
                  <div class="main-image-item" ngxSlickItem>
                    <ng-container *ngIf="item.isVideo == false">
                      <img [alt]="product.name" [lightbox]="lightBoxIndex" [src]="item.link" priority/>
                    </ng-container>
                    <ng-container *ngIf="item.isVideo == true">
                      <iframe [src]="item.link"
                              allowfullscreen [frameBorder]="0"></iframe>
                    </ng-container>
                  </div>
                </ng-container>
              </ngx-slick-carousel>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- INFO PART -->
      <div class="product-inf">

        <div class="product-title">
          <div class="ttl"
               [style.color]="getFieldValue('title-color')"
               [style.font-size]="getFieldValue('title-size')+'px'">{{product.name}}</div>
          <div class="share-product" [matMenuTriggerFor]="shareMenu">{{ 'PRODUCT.SHARE.TITLE' | translate }}</div>
        </div>

        <!-- PRICE -->
        <div class="product-price">
          <div class="prices">
            <div class="real-price"
                 [style.color]="getFieldValue('price-color')"
                 [class.with-shadow]="getFieldValue('price-with-shadow')"
                 [style.font-size]="getFieldValue('price-size')+'px'">
              {{price - basicHelperService.getDiscountValue(discounts, price) | price}}
            </div>
            <div *ngIf="getFieldValue('hide-init-price') != true && basicPrice > 0"
                 [class.with-shadow]="getFieldValue('basic-with-shadow')"
                 [style.color]="getFieldValue('basic-price-color')"
                 [style.font-size]="getFieldValue('basic-price-size')+'px'"
                 class="init-price">
              {{basicPrice| price}}
            </div>
            <div class="discount-percent" *ngIf="discountPercent > 0">-{{discountPercent}} %</div>
          </div>
        </div>

        <!-- RATE -->
        <div *ngIf="getFieldValue('hide-reviews-rate') != true"
             class="product-reviews">
          <div class="rate neo-rate">
            <bar-rating [(rate)]="product.rate" [max]="5" [readOnly]="true"></bar-rating>
          </div>
          <div class="count">{{product.rateCount || 0}} {{'PRODUCT.REVIEWS' | translate}}</div>
        </div>

        <!-- DISCOUNT -->
        <div *ngIf="discounts" class="product-discounts">
          <ng-container *ngFor="let discount of discounts;">
            <div class="discount-item">
              <span *ngIf="discount.value">{{discount.value | price}}</span>
              <span *ngIf="discount.percent">{{discount.percent}}%</span>
              {{discount.label}}
              <ng-container *ngIf="discount.minimumRequirementQuantity">
                <strong>({{'PRODUCT.DISCOUNT.MINIMUM_QUANTITY' | translate}} {{discount.minimumRequirementQuantity}})</strong>
              </ng-container>
              <ng-container *ngIf="discount.minimumRequirementAmount">
                <strong>({{'PRODUCT.DISCOUNT.MINIMUM_AMOUNT' | translate}} {{discount.minimumRequirementAmount | price}}
                  )</strong>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <ng-container
          *ngIf="(product.variations && product.variations.length > 0) || product.stock > 0 || product.disableOutOfStock; else outOfStock">

          <!-- COUNTDOWN APPLICATION -->
          <lib-count-down *ngIf="countDown" [countDown]="countDown"></lib-count-down>

          <div *ngIf="product.variations && product.variations.length > 0" class="product-options">
            <div id="product-options-anchor" class="anchor"></div>
<!--            <div class="option-ttl">{{'PRODUCT.OPTIONS' | translate}}</div>-->
            <ng-container *ngIf="getFieldValue('variation-mode') != 'select'; else selectVariation">
              <div class="option-area" *ngFor="let option of product.options; let index = index">
                <div class="oa-ttl">{{option.name}}</div>
                <div class="oa-items">
                  <ng-container *ngIf="option.type == variationOptionType.COLOR">
                    <div class="option-color"
                         [class.disabled]="isOptionDisabled(optionValue.value, index)"
                         [class.checked]="selectedOptions[index] == optionValue.value"
                         (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                         *ngFor="let optionValue of option.optionValues">
                      <div class="color-content" [style.background-color]="optionValue.color"></div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="option.type == variationOptionType.IMAGE">
                    <div class="option-image"
                         [class.disabled]="isOptionDisabled(optionValue.value, index)"
                         [class.checked]="selectedOptions[index] == optionValue.value"
                         (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                         *ngFor="let optionValue of option.optionValues">
                      <img *ngIf="optionValue.media" [src]="urlVariantSmallMedia(optionValue.media)"/>
                      <img *ngIf="!optionValue.media" src="/assets/img/neo-picture.svg"/>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!option.type || option.type == variationOptionType.TEXT">
                    <div class="option-value"
                         [class.disabled]="isOptionDisabled(optionValue.value, index)"
                         [class.checked]="selectedOptions[index] == optionValue.value"
                         (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                         *ngFor="let optionValue of option.optionValues">{{optionValue.value}}</div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-template #selectVariation>
              <mat-form-field appearance="outline">
                <mat-label>{{'PRODUCT.CHOOSE_OPTION' | translate}}</mat-label>
                <mat-select (selectionChange)="changeVariation()" [(ngModel)]="selectedVariation">
                  <ng-container *ngFor="let variation of product.variations">
                    <mat-option
                      [disabled]="variation.disabled || (!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0))"
                      [value]="variation.id">
                      <span>{{variation.optionValues.join(' - ') + ' - '}} {{variation.price - basicHelperService.getDiscountValue(variation.discounts, variation.price) | price}}</span>
                      <span
                        *ngIf="!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0)">({{'PRODUCT.OUT_OF_STOCK_TITLE' | translate}}
                        )</span>
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </div>

          <lib-neo-product-checkout
            [data]="data"
            [expressFG]="expressFG"
            [checkoutLabel]="checkoutLabel"
            [checkoutFields]="checkoutFields"
            [maxQuantity]="maxQuantity"
            [quantity]="quantity"
            [expressLoading]="formLoading"
            (addToCartAction)="addToCart()"
            (refreshQuantityAction)="refreshQuantity($event)"
            (submitClassicCheckoutAction)="submitClassicCheckout()"
            (submitExpressCheckoutAction)="submitExpressCheckout()"
          ></lib-neo-product-checkout>

        </ng-container>
        <ng-template #outOfStock>
          <div class="out-of-stock">
            <div class="big-ttl">{{'PRODUCT.OUT_OF_STOCK_TITLE' | translate}}</div>
            <div class="desc">{{'PRODUCT.OUT_OF_STOCK_DESC' | translate}}</div>
          </div>
        </ng-template>

        <div class="product-description" *ngIf="description && !getFieldValue('separate-description')">
          <div class="desc-val ql-container ql-snow">
            <div class="ql-editor" [innerHTML]="description"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="neo-section-layout v-padding" *ngIf="description && getFieldValue('separate-description')">
    <div class="product-description full ql-editor">
      <div class="desc-val ql-container ql-snow">
        <div class="ql-editor" [innerHTML]="description"></div>
      </div>
    </div>
  </div>
</div>


<ng-container
  *ngIf="product && ((product.variations && product.variations.length > 0) || product.stock > 0 || product.disableOutOfStock)">
  <div [class.hidden]="getFieldValue('show-fixed-bar') == false" class="fixed-bar">
    <div class="fb-content">

      <div *addToCart
           (click)="addToCart()"
           [style.color]="getFieldValue('add-cart-color')"
           [style.background-color]="getFieldValue('add-cart-bg-color')"
           [style.border-radius]="getFieldValue( 'fixed-btn-border-radius')+'px'"
           [style.font-size]="getFieldValue( 'fixed-btn-font-size')+'px'"
           [style.line-height]="getFieldValue( 'fixed-btn-line-height')+'px'"
           [class.lower]="getFieldValue('buy-lower') == true"
           class="neo-btn add-cart">
        {{'PRODUCT.ADD_TO_CART' | translate}}
      </div>

      <button (click)="submitClassicCheckout()" *ngIf="!expressFG"
              [loading]="formLoading" loadingBtn
              [class.shake-btn]="getFieldValue('shake-btn')== true"
              [style.color]="getFieldValue('buy-color')"
              [style.background-color]="getFieldValue('buy-bg-color')"
              [style.border-radius]="getFieldValue( 'fixed-btn-border-radius')+'px'"
              [style.font-size]="getFieldValue( 'fixed-btn-font-size')+'px'"
              [style.line-height]="getFieldValue( 'fixed-btn-line-height')+'px'"
              [class.lower]="getFieldValue('buy-lower') == true"
              class="neo-btn buy-now with-ic">
        <div class="ic" *ngIf="getFieldValue('buy-show-icon') == true"></div>
        <span>{{ getFieldValue('buy-label') || 'PRODUCT.BUY_NOW' | translate}}</span>
      </button>
      <button (click)="submitExpressCheckout()"
              *ngIf="expressFG"
              [class.shake-btn]="getFieldValue('shake-btn') == true"
              [loading]="formLoading"
              [style.color]="getFieldValue('buy-color')"
              [style.background-color]="getFieldValue('buy-bg-color')"
              [style.border-radius]="getFieldValue( 'fixed-btn-border-radius')+'px'"
              [style.font-size]="getFieldValue( 'fixed-btn-font-size')+'px'"
              [style.line-height]="getFieldValue( 'fixed-btn-line-height')+'px'"
              [class.lower]="getFieldValue('buy-lower') == true"
              class="neo-btn buy-now with-ic" loadingBtn>
        <div class="ic" *ngIf="getFieldValue('buy-show-icon') == true"></div>
        <span>{{ getFieldValue('buy-label') || 'PRODUCT.BUY_NOW' | translate }}</span>
      </button>

    </div>
  </div>
</ng-container>


<mat-menu #shareMenu>
  <div id="share-menu">
    <button mat-menu-item shareButton="facebook"
            [url]="window.location.href">{{ 'PRODUCT.SHARE.FACEBOOK' | translate }}</button>
    <button mat-menu-item shareButton="whatsapp"
            [url]="window.location.href">{{ 'PRODUCT.SHARE.WHATSAPP' | translate }}</button>
    <button mat-menu-item shareButton="pinterest"
            [url]="window.location.href">{{ 'PRODUCT.SHARE.PINTEREST' | translate }}</button>
    <button mat-menu-item shareButton="twitter"
            [url]="window.location.href">{{ 'PRODUCT.SHARE.X' | translate }}</button>
    <button mat-menu-item shareButton="email"
            [url]="window.location.href">{{ 'PRODUCT.SHARE.EMAIL' | translate }}</button>
    <button mat-menu-item shareButton="sms" [url]="window.location.href">{{ 'PRODUCT.SHARE.SMS' | translate }}</button>
    <button mat-menu-item shareButton="copy"
            [url]="window.location.href">{{ 'PRODUCT.SHARE.COPY_LINK' | translate }}</button>
  </div>
</mat-menu>
