import { Product } from "./product.model";
import { ProductVariation } from "./product-variation.model";
import { Discount } from "./discount.model";
import { Upsell } from "./upsell.model";

class AbstractCart {
  comment: string;
  total: number;
}

export class Cart extends AbstractCart {
  items: CartItem[];
  discounts: Discount[];
}

export class CartForm extends AbstractCart {
  items: CartFormItem[];
}

class AbstractCartItem {
  id: string;
  quantity: number;
  total: number;
}

export class CartItem extends AbstractCartItem {
  product: Product;
  variation?: ProductVariation;
  discounts: Discount[];
  upsell: Upsell;
}

export class CartFormItem extends AbstractCartItem {
  product: string;
  variation?: string;
  upsell?: string;
}
