import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CommonService, ComponentLookup, Product, ProductService, StorageService, StoreService} from '@app/_shared';
import {DomSanitizer, makeStateKey, TransferState} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformServer} from "@angular/common";

@Component({
  selector: 'lib-neo-landing-page-product-desc',
  templateUrl: './neo-landing-page-product-desc.component.html',
  styleUrls: ['./neo-landing-page-product-desc.component.scss']
})
@ComponentLookup('neo-landing-page-product-description')
export class NeoLandingPageProductDescComponent extends ShopyanSectionComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  htmlText: any;

  constructor(private _sanitizer: DomSanitizer,
              private commonService: CommonService,
              private productService: ProductService,
              private router: Router,
              private storageService: StorageService,
              private transferState: TransferState,
              @Inject(PLATFORM_ID) private platformId: any,
              private storeService: StoreService) {
    super();
  }

  ngOnInit(): void {
    if (this.buildMode) {
      let landingPageSlug = this.storageService.getData('landing_page_slug');
      if (landingPageSlug) {
        this.findProductByLandingPageSlug(JSON.parse(landingPageSlug));
      } else {
        this.getRandomProduct();
      }
    } else if (isPlatformServer(this.platformId)) {
      let slug = this.storeService.currentPath.split('/')[this.storeService.currentPath.split('/').length - 1];
      this.findProductByLandingPageSlug(slug);
    } else {
      const description = this.transferState.get(makeStateKey(this.data.code + '-description'), null as any);
      this.htmlText = this._sanitizer.bypassSecurityTrustHtml(description);
    }
  }

  findProductByLandingPageSlug(slug: string): void {
    let url = `${isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl}/landing-pages/get-product-id/${slug}`;
    this.subscription.add(this.commonService.get(url, true).subscribe((response: any) => {
      this.findProduct(response);
    }));
  }

  /**
   * Find product
   * @param productId
   */
  findProduct(productId: string): void {
    if (!productId) {
      this.router.navigateByUrl(`/not-found`).then();
    }
    this.subscription.add(this.productService.findProduct(productId, false).subscribe((response: any) => {
      this.initDescription(response);
    }));
  }


  /**
   * Filter products for build mode
   */
  getRandomProduct(): void {
    this.subscription.add(this.productService.findRandomProduct().subscribe((response: any) => {
      if (response) {
        this.initDescription(response);
      }
    }));
  }

  /**
   * Init description
   * @param product
   * @private
   */
  private initDescription(product: Product): void {
    if (product.description) {
      let description = product.description.replace(new RegExp('/product/original/', 'g'), '/product/medium/');
      description = description.replace(new RegExp('/product/original/', 'g'), '/product/medium/');
      description = description.replace(new RegExp('<img ', 'g'), '<img priority fill alt="store" ');
      this.transferState.set(makeStateKey(this.data.code + '-description'), description as any);
      this.htmlText = this._sanitizer.bypassSecurityTrustHtml(description);
    }
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
