import {ComponentRef, Inject, Injectable, ViewContainerRef} from '@angular/core';
import {ComponentLookupRegistry, GraphBlock, GraphFieldValue, GraphPage} from "@app/_shared";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ShopyanTemplateFactoryService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  /**
   * Build page sections
   * @param target
   * @param page
   * @param storeID
   * @param buildMode
   */
  buildSections(target: ViewContainerRef, page: GraphPage, storeID: string, buildMode?: boolean): void {
    target.clear();
    page.sections?.forEach(graphSection => {
      const component = ComponentLookupRegistry.get(graphSection.sectionCode);
      if (component && !graphSection.hidden) {
        let componentRef: ComponentRef<any> = target.createComponent(component);
        componentRef.instance.data = {...graphSection};
        componentRef.instance.buildMode = buildMode;
        componentRef.instance.storeID = storeID;
      }
    });

  }


  /**
   * Init setting
   * @param settings
   */
  initGenericSettings(settings: GraphBlock[]): void {
    if (settings?.length > 0) {
      let styleString = ''
      settings.forEach((block: GraphBlock) => {
        block.values.forEach((item: GraphFieldValue) => {
            if (+item.value) {
              styleString += `--${item.fieldCode}:`+ item.value + `px;`;
            } else {
              styleString += `--${item.fieldCode}:`+ item.value+';' ;
            }
        })
      });
      // @ts-ignore
      this.document.body.style = styleString;
    }
  }


}
