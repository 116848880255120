import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  CartItem,
  NeoUpsellModalComponent,
  ShopyanCartHelperService,
  StorageService,
  Upsell,
  UpsellPage
} from "@app/_shared";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";

@Injectable({providedIn: 'root'})
export class UpsellService implements OnDestroy {

  subscription: Subscription = new Subscription();

  constructor(private http: HttpClient,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: any,
              private basicHelperService: ShopyanCartHelperService,
              private storageService: StorageService,
              private dialog: MatDialog) {
  }

  public findUpsells(upsellProducts: UpsellProductForm[], page: UpsellPage): Observable<any> {
    let url = `${isPlatformServer(this.platformId) ? environment.privateProductApiUrl : environment.pubicProductApiUrl}/upsells/${page}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, upsellProducts, httpOptions);
  }


  /**
   * Check if any upsells exists
   */
  public checkUpSells(page: UpsellPage, storeId: string): void {
    let cart: any;
    let storageData = this.storageService.getData('car');
    if (storageData) {
      cart = JSON.parse(storageData);
    }

    // If coming from express checkout
    let expressStorageData = this.storageService.getData('express_cart');
    if (expressStorageData) {
      cart = JSON.parse(expressStorageData);
    }
    if (cart) {
      let upsellProducts: UpsellProductForm[] = [];
      cart.items?.forEach((cartItem: CartItem) => {
        let upsellProduct = new UpsellProductForm();
        upsellProduct.product = cartItem.product.id;

        if (cartItem.variation) {
          upsellProduct.variations = [];
          upsellProduct.variations.push(cartItem.variation.id);
        }
        if (cartItem.upsell) {
          upsellProduct.upsell = cartItem.upsell.id;
        }
        upsellProducts.push(upsellProduct);
      });

      if (upsellProducts.length === 0) {
        return;
      }
      this.subscription.add(this.findUpsells(upsellProducts, page).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.openUpsellModal(response, page, storeId);
        }
      }));
    }
  }

  private openUpsellModal(upsells: Upsell[], page: UpsellPage, storeId: string): void {
    const config = {
      width: '450px',
      autoFocus: false,
      panelClass: ['yan-dialog'],
      data: {
        upsells: upsells,
        page: page,
        storeId: storeId
      }
    };
    const dialogRef = this.dialog.open(NeoUpsellModalComponent, config);
    this.subscription.add(this.router.events.subscribe(() => {
      dialogRef.close();
    }));
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export class UpsellProductForm {
  product: string;
  variations: string[];
  upsell: string;
}
