import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService, Event, MetaData, PageData, PageGraphData, ShopyanTemplateFactoryService} from "@app/_shared";
import {StoreService} from "@app/_shared/service/store.service";
import {combineLatest, Subscription} from "rxjs";
import {StorageService} from "@app/_shared/service/storage.service";
import {EventService} from "@app/_shared/service/event-service";
import {HttpClient} from "@angular/common/http";
import {DOCUMENT, isPlatformServer} from "@angular/common";
import {MetaDataService} from "@app/_shared/service/meta-data.service";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-store-page',
  templateUrl: './store-page.component.html',
  styleUrls: ['./store-page.component.scss']
})
export class StorePageComponent implements OnInit, OnDestroy {

  subscribers: Subscription = new Subscription();
  windowScrolled = false;
  buildMode = false;
  loading = false;

  @ViewChild('viewContainerRef', {read: ViewContainerRef, static: true})
  public viewContainerRef: ViewContainerRef

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject('ORIGIN_HOSTNAME') public originHostname: string,
    @Optional() @Inject('ORIGIN_URL') public originUrl: string,
    @Optional() @Inject('REFERER') public referer: string,
    private router: Router,
    private http: HttpClient,
    public storeService: StoreService,
    private storageService: StorageService,
    private eventService: EventService,
    private authService: AuthService,
    private metaDataService: MetaDataService,
    private transferState: TransferState,
    private translate: TranslateService,
    private conversionService: ConversionService,
    private shopyanTemplateFactoryService: ShopyanTemplateFactoryService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

  ngOnInit(): void {
    if (window.self === window.top) {
      if (isPlatformServer(this.platformId)) {
        this.buildServerSidePage();
      } else {
        this.buildClientSidePage();
      }
    } else {
      this.buildMode = true;
      // Send a message to the builder to tell that the store is loaded
      window.parent.postMessage("loaded", '*');
    }
  }

  /**
   * Build page : SERVER SIDE
   * @private
   */
  private buildServerSidePage(): void {

    this.loading = true;
    this.subscribers.add(this.storeService.getPageData(this.originHostname, this.originUrl, true).subscribe({
      next: (response: PageGraphData) => {
        if (response) {
          this.loading = false;
          this.transferState.set(makeStateKey('path'), this.originUrl as any);
          this.transferState.set(makeStateKey('data'), response.pageData as any);
          this.storeService.storeId = response.pageData.storeId;
          this.storeService.currentPath = response.pageData.path;
          this.shopyanTemplateFactoryService.buildSections(this.viewContainerRef, response.pageData.graphPage, response.pageData.storeId, false);
          this.shopyanTemplateFactoryService.initGenericSettings(response.pageData.settings);
          this.translate.use(response.pageData.defaultLanguage);
          this.initMeta(response.metaData);
        }
      }
    }));
  }

  /**
   * Build page client side
   * @private
   */
  private buildClientSidePage(): void {
    const pageData = this.transferState.get(makeStateKey('data'), null as any);
    const path = this.transferState.get(makeStateKey('path'), null as any);
    if (path != this.router.url) {
      this.loading = true;
      this.subscribers.add(this.storeService.getPageData(window.location.hostname, this.router.url, false).subscribe({
        next: (response: PageGraphData) => {
          this.loading = false;
          this.initClientSidePageData(response.pageData);
        },
        error: err => {
          if (err?.status !== 403 && err?.status !== 503) {
            this.router.navigateByUrl('/not-found').then();
          }
        }
      }));
    } else {
      this.initClientSidePageData(pageData);
    }
  }

  /**
   * Init client side page data
   * @private
   */
  private initClientSidePageData(pageData: PageData): void {
    if (pageData) {
      this.storeService.storeId = pageData.storeId;
      this.storeService.currentPath = pageData.path;
      // Fav icon
      if (pageData.thumbnail) {
        let favIcon: any = document.querySelector('#favIcon');
        favIcon.href = environment.mediaURL + '/' + pageData.storeId + "/store/small/" + pageData.thumbnail + ".webp";
      }

      //Clear express cart if exit checkout
      if (!this.router.url.includes('checkout')) {
        this.storageService.removeData('express_cart');
      }

      if (pageData.cartDisabled) {
        this.storageService.saveData('dis_add_to', JSON.stringify(pageData.cartDisabled));
      } else {
        this.storageService.removeData('dis_add_to');
      }
      this.storageService.saveData('cur', JSON.stringify(pageData.currency));
      this.storageService.saveData('rate', JSON.stringify(pageData.conversionRate));
      this.storageService.saveData('mode', JSON.stringify(pageData.accountMode));
      this.storageService.saveData('rounding', JSON.stringify(pageData.commercialRounding));
      this.storageService.saveData('lang', JSON.stringify(pageData.languages));
      this.initLanguages(pageData.languages, pageData.defaultLanguage);
      this.storageService.removeData('current_product_name');

      this.shopyanTemplateFactoryService.buildSections(this.viewContainerRef, pageData.graphPage, pageData.storeId, false)
      this.shopyanTemplateFactoryService.initGenericSettings(pageData.settings);

      if (this.authService.isAuthenticated()) {
        this.subscribers.add(combineLatest([
          this.authService.getCart(),
          this.authService.getWishList()
        ]).subscribe(([cartResponse, wishListResponse]: [any, any]) => {
          this.storageService.saveData('car', JSON.stringify(cartResponse));
          this.storageService.saveData('wis', JSON.stringify(wishListResponse));
        }));
      }
      this.sendVisitEvent(pageData.storeId);
      this.subscribers.add(this.conversionService.sendPageViewEvent().subscribe());
    } else {
      this.router.navigateByUrl('/not-found').then();
    }
  }


  /**
   * Init default language
   * @param languages
   * @param defaultLanguage
   * @private
   */
  private initLanguages(languages: string[], defaultLanguage: string): void {
    const selectedLang = this.storageService.getData('my_lang');
    if (selectedLang && languages && languages.indexOf(selectedLang) >= 0) {
      this.translate.use(selectedLang);
    } else if (defaultLanguage) {
      this.translate.use(defaultLanguage);
      this.storageService.saveData('my_lang', defaultLanguage);
    }
  }


  /**
   * Init page meta data
   * @param metaData
   * @private
   */
  private initMeta(metaData: MetaData): void {
    const imageUrl = metaData.imageUrl;
    this.metaDataService.updateMeta(this.originHostname + this.originUrl, metaData.title, metaData.description, imageUrl);
  }

  /**
   * Send visit event
   * @param storeId
   */
  sendVisitEvent(storeId: string): void {
    let visit: Event = new Event();
    const visitData = this.storageService.getData('visit');
    if (visitData) {
      visit = JSON.parse(visitData);
    }
    if (!visit.createdDate || new Date() > new Date(new Date(visit.createdDate).getTime() + 30 * 60000)) {
      const visitEvent = {
        eventId: visit.eventId,
        createdDate: new Date()
      }
      this.eventService.sendVisit(visitEvent, storeId).subscribe(value => {
        visitEvent.eventId = value;
        this.storageService.saveData('visit', JSON.stringify(visitEvent))
      });
    }
  }

  /**
   * Builder events
   * @param event
   */
  @HostListener('window:message', ['$event'])
  onPostMessage(event: any) {
    if (event.data.type === 'build' && event.data.page && event.data.settings) {
      this.storeService.storeId = event.data.storeId;
      this.storageService.saveData('cur', JSON.stringify(event.data.currency));

      // Landing page management
      if (event.data.landingPageSlug) {
        this.storageService.saveData('landing_page_slug', JSON.stringify(event.data.landingPageSlug));
      } else {
        this.storageService.removeData('landing_page_slug');
      }
      this.storageService.saveData('lang', JSON.stringify(event.data.languages));
      this.initLanguages(event.data.languages, event.data.defaultLanguage);

      this.shopyanTemplateFactoryService.buildSections(this.viewContainerRef, event.data.page, event.data.storeId, true);
      this.shopyanTemplateFactoryService.initGenericSettings(event.data.settings);

    } else if (event.data.type == 'select') {
      setTimeout(() => {
        //Remove previously selected sections
        const elements = document.getElementsByClassName('yan-section-selected');
        Array.from(elements).forEach(item => item.classList.remove('yan-section-selected'));

        // Select the current one
        const el = document.getElementById(event.data.section);
        el?.scrollIntoView({behavior: "smooth"});
        el?.classList.add('yan-section-selected');
      }, 300);
    }

  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    if (target.id) {
      window.parent.postMessage(target.id, '*');
    }
  }

  /**
   * Scroll event
   * @param e
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    this.windowScrolled = window.scrollY > 100;
  }


  scrollToTop(): void {
    document.getElementById('page-anchor')?.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  ngOnDestroy(): void {
    this.subscribers.unsubscribe();
  }

}
